@import '/src/styles/colors.scss';
.pagination {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 18px;
  width: -webkit-fill-available;
  right: 32px;
  left: 32px;
}

.pagination_section {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;

  @media (max-width: 465px) {
    gap: 5px;
  }
  @media (max-width: 423px) {
    gap: 0;
  }
}

.results_per_page_dropdown {
  border: 1px solid $button-border;
  border-radius: 6px;
  height: 25px;
  width: 50px;
}
