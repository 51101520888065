@import '../../../styles/colors.scss';

.leave-dates-pickers,
.leave-select,
.leave-desc {
  margin-bottom: 25px;
  margin-top: 25px;
}

.leave-select {
  margin-top: 25px;
  max-width: 48%;
}

.existing-file-list {
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
  gap: 15px;
}

.chip_color {
  div {
    border-color: $summary_color_2;
    background-color: $summary_color_2_background;
    color: $badge_approved;
  }
  div:hover {
    border-color: $summary_color_2;
    background-color: $summary_color_2 !important;
  }
  svg {
    color: $badge_approved !important;
  }
}

.leave-dates-pickers {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 0;

  .MuiStack-root {
    width: 100%;
    padding-top: 0;

    .MuiStack-root {
      width: 100%;
    }
  }

  .date-picker-start {
    width: 48%;
  }

  .date-picker-end {
    width: 48%;
  }

  p {
    display: block;
    font-size: 14px;
    font-weight: 600;
  }
  input {
    height: 0.4375em !important;
  }
  p.Mui-error {
    color: $input-error !important;
    font-size: 14px;
  }
}

@media (max-width: 992px) {
  .leave-dates-pickers {
    flex-direction: column !important;
  }
  .date-picker-start,
  .date-picker-end {
    width: 100% !important;
  }
}

.modal_footer_buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;

  .btn.base {
    margin: 0;
  }

  .btn.success {
    width: 100px;
    justify-content: center;
  }
}
