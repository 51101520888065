@import '../../../styles/colorPalette';

.manage-employe-title {
  margin: 12px 0;
}

.registration-step-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  margin: 24px 0;

  .step-number {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: $admin-primary;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
  }

  .step-info {
    .base_regular {
      color: $admin-primary;
      font-size: 16px;
      font-weight: 400;
    }

    .small_medium {
      color: #80809a;
      font-size: 14px;
      font-weight: 400;
      margin-top: 6px;
      display: block;
    }
  }
}

.registration-form {
  width: 70%;

  .margin-24 {
    margin-bottom: 24px;
  }

  .width-half {
    width: 50%;
  }

  .name-input-wrapper {
    width: calc(100% - 32px);
  }
}
