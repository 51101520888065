@import '../../../styles/shadow';

.admin-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 16px 18px 32px;
  box-shadow: 0px 2px 50px 4px #3341550d;
  position: relative;
  z-index: 1000;

  .admin-clock {
    display: flex;
    align-items: center;
    gap: 8px;

    span {
      color: #1e293b;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .admin-header-user {
    display: flex;
    align-items: center;

    .admin-notification {
      padding-right: 16px;
      margin-right: 16px;
      border-right: 1px solid #e2e8f0;

      .admin-notification-btn {
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: none;
        cursor: pointer;
        outline: none;
        border-radius: 8px;
        transition: background-color 0.2s ease-in-out;

        img {
          width: 24px;
          height: 24px;
        }

        &:hover {
          background-color: #f2f2f2;
        }
      }
    }

    .admin-user {
      display: flex;
      align-items: center;
      gap: 6px;
      margin-left: 12px;
      position: relative;

      .admin-user-name {
        font-size: 14px;
        font-weight: 500;
        color: #1e293b;
      }

      .admin-user-role {
        font-size: 12px;
        color: #64748b;
      }

      .admin-user-btn {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: none;
        cursor: pointer;
        outline: none;
        border-radius: 8px;
        transition: background-color 0.2s ease-in-out;
        align-self: center;
        margin-left: 12px;

        &:hover {
          background-color: #f2f2f2;
        }
      }

      .admin-user-btn.u-active {
        img {
          transform: rotate(180deg);
        }
      }

      .admin-user-menu {
        @extend .shadow;
        position: absolute;
        background-color: #ffffff;
        padding: 12px;
        right: 0;
        top: 45px;
        border-radius: 4px;
        min-width: 170px;

        button {
          border: 1px solid #e2e8f0;
          border-radius: 4px;
          width: 100%;
          padding: 8px 12px;
          cursor: pointer;
          transition: background-color 0.2s ease-in-out;
          font-size: 14px;
          font-weight: 400;

          &:hover {
            background-color: #f2f2f2;
          }
        }
      }
    }
  }
}
