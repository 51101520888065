@import '../../../styles/colorPalette';

.dashboard-wrapper {
  height: calc(100% - 76px);
  display: flex;
  overflow: hidden;
  position: relative;

  .spinner-main-wrapper {
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px;

    .form-actions {
      padding: 24px 16px;
      display: flex;
      gap: 10px;
      justify-content: flex-end;

      border-bottom: 1px solid #f5f5f5;
      border-left: 1px solid #f5f5f5;
      border-right: 1px solid #f5f5f5;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  .sidebar-wrapper {
    display: flex;
    height: 100%;
    background-color: #ffffff;

    .sidebar-main-wrapper {
      display: flex;
      flex-direction: column;
      height: calc(100% - 32px);
      padding: 16px;

      .sidebar-menus {
        flex: 1;

        .ps-menuitem-root,
        .ps-menu-button {
          border-radius: 4px;
        }

        .ps-active {
          background-color: $admin-primary;
          color: #fbfbfb;

          img {
            filter: brightness(2) contrast(1);
          }
        }
      }

      .sidebar-heading-wrapper {
        margin-bottom: 4px;

        text {
          color: #64748b;
          font-size: 12px;
        }
      }

      .sidebar-footer {
        text-align: center;

        div {
          background-color: #eeeeee;
          height: 2px;
          width: 100%;
          margin-bottom: 16px;
        }

        .collapse-btn {
          display: flex;
          gap: 8px;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          padding: 4px;
          border-radius: 4px;
          transition: background-color 0.2s ease-in-out;
          border: none;
          border: none;
          background-color: transparent;

          span {
            color: #555579;
            font-size: 14px;
            font-weight: 400;
          }

          &:hover {
            background-color: #f2f2f2;
          }
        }
      }

      .sidebar-footer.collapsed {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}

.collapsed.sidebar-footer {
  .collapse-btn {
    width: 100%;
  }
}
