.employee-summary {
  .employee-summary-container {
    table {
      width: 100%;
      border-spacing: 0;

      thead {
        background-color: #fafafa;

        tr {
          th {
            padding: 6px 0;
            color: #010136;
            font-size: 14px;
            font-weight: 500;
            border: 1px solid #e5e5ee;

            &:first-child {
              border-top-left-radius: 6px;
            }

            &:last-child {
              border-top-right-radius: 6px;
            }
          }
        }
      }

      tbody {
        tr {
          transition: background-color 0.2s ease;
          
          td {
            padding: 12px 0;
            border: 1px solid #e5e5ee;
            text-align: center;
            font-size: 13px;
            color: #1f1f1f;
            font-weight: 400;

            .employee-status-chip {
              display: flex;
              justify-content: center;
              gap: 4px;
              flex-wrap: wrap;          
            }
          }

          .admin-edit {
            background-color: transparent;
            border: 0;
            cursor: pointer;
            padding: 5px 10px;
            border-radius: 4px;
            transition: ease-in-out background-color 0.2s;
            outline: none;

            &:hover {
              background-color: #f2f2f2;
            }
          }

          &:last-child {
            td:first-child {
              border-bottom-left-radius: 6px;
            }

            td:last-child {
              border-bottom-right-radius: 6px;
            }
          }

          &:hover {
            background-color: #f2f2f2;

            td {
              background-color: transparent !important;
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  .employee-inactive-title {
    margin: 32px 0 16px;
  }
}

.manage-employe-container {
  border-top: 1px solid #f5f5f5;
  border-left: 1px solid #f5f5f5;
  border-right: 1px solid #f5f5f5;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.employee-summary,
.manage-employe-container {
  padding: 12px 24px;
  flex: 1;
  overflow-x: auto;
}

.clickable {
  cursor: pointer;
}
