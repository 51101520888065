.avatar {
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

.defaultAvatar {
  background-color: #0d8abc;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar.sm {
  height: 42px;
  width: 42px;
  font-size: 18px;
}
.avatar.md {
  width: 75px;
  height: 75px;
  font-size: 35px;
}
.avatar.lg {
  width: 120px;
  height: 120px;
  font-size: 45px;
}
.avatar.xl {
  width: 180px;
  height: 180px;
  font-size: 55px;
}

.avatar.xsm {
  height: 32px;
  width: 32px;
  font-size: 16px;
}
