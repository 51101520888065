@import '../../../styles/colorPalette';

.input-select-wrapper {
  .input-label {
    color: $admin-primary;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;

    .input-required {
      color: #f64c4c;
      margin-left: 2px;
    }
  }

  .input-box {
    width: 100%;
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d4d4dd;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    padding: 8px 12px;
    font-size: 16px;
    outline: none;
    min-height: 40px;

    &:focus {
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 2px $white,
        0px 0px 0px 4px $focus;
    }
  }

  .input-error {
    border: 1px solid #f64c4c;
  }

  .error {
    color: #f64c4c;
    font-size: 12px;
    margin-top: 5px;
  }
}
