@import '/src//styles/colors.scss';
.icon {
  width: 22px;
  padding: 0 10px;
  display: flex;
}

// color variants
.icon.primary {
  filter: invert(20%) sepia(25%) saturate(351%) hue-rotate(197deg)
    brightness(89%) contrast(92%);
}
.icon.secondary {
  filter: invert(44%) sepia(26%) saturate(215%) hue-rotate(181deg)
    brightness(93%) contrast(85%);
}
.icon.white {
  filter: invert(91%) sepia(15%) saturate(74%) hue-rotate(201deg)
    brightness(104%) contrast(89%);
}
.icon.grey {
  filter: invert(18%) sepia(9%) saturate(14%) hue-rotate(352deg) brightness(99%)
    contrast(96%);
}
.icon.danger {
  filter: invert(11%) sepia(89%) saturate(5515%) hue-rotate(358deg)
    brightness(74%) contrast(82%);
}

.disabled {
  pointer-events: none;
  opacity: 0.7;
}

.enabled {
  opacity: 1;
  cursor: pointer;
}

.icon.small {
  height: 12px;
}
.delete_icon {
  img {
    height: 22px;
  }
}
