@import '/src/styles/colors.scss';

// leave summary card section
.mainItem1_summary_cards {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
  flex-wrap: wrap;
  row-gap: 18px;
  column-gap: 18px;

  #summary_each_type {
    display: flex;
    gap: 18px;
    flex-wrap: wrap;
  }
}

.mainItem1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: -webkit-fill-available;
  left: 32px;
  right: 32px;
  padding: 8px 0;
}

.mainItem1_left {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;

  .btn {
    height: 32px;
    margin: 9px 0px;
  }

  @media (max-width: 721px) {
    div:nth-child(4) {
      display: none;
    }
  }
  @media (max-width: 701px) {
    div:nth-child(4) {
      display: flex;
    }
  }
  @media (max-width: 446px) {
    div:nth-child(4) {
      display: none;
    }
  }
  @media (max-width: 406px) {
    div:nth-child(5) {
      display: none;
    }
    button {
      width: -webkit-fill-available;
      justify-content: center;
    }
  }
}

.mainItem1_right {
  display: flex;
  flex-direction: row;
  align-items: center;
  display: none;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid $gray3; /* Light grey */
  border-top: 10px solid $green2; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.center {
  margin: auto;
  width: 5%;
  padding-top: 15%;
}

// wrapper for table
.table_wrapper {
  overflow-x: auto;
  height: 100%;

  // Styling for table component
  table {
    margin: 0;
    border-spacing: 0px;
    border: none;
    display: flex;
    flex-flow: column;
    width: 100%;
    border-collapse: collapse;
    height: 100%;

    @media (max-width: 1264px) {
      height: calc(100vh - 382px);
    }
    @media (max-width: 1270px) {
      height: calc(100vh - 535px);
    }
    @media (max-width: 1018px) {
      height: calc(100vh - 555px);
    }
    @media (max-width: 877px) {
      height: calc(100vh - 573px);
    }
    @media (max-width: 705px) {
      height: calc(100vh - 723px);
    }

    thead {
      width: -webkit-fill-available;
      left: 32px;
      right: 32px;
      flex: 0 0 auto;
      border-radius: 4px 4px 0 0;
      overflow: hidden;

      th {
        color: $gray1;
        background-color: $gray3;
        font-size: small;

        &:last-child {
          display: flex;
          justify-content: space-around;
        }
      }

      tr {
        display: grid;
        grid-template-columns: 150px 1fr 110px 100px 170px 170px 110px 1fr 100px;
        width: 100%;

        th > div {
          padding: 16px 10px;
        }
      }
    }

    // scrollbar
    tbody::-webkit-scrollbar {
      width: 12px;
      height: 12px;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      height: 10px;
      border-radius: 8px;
      border: 4px solid transparent;
      background-clip: content-box;
      background-color: $scrollbar;
    }

    ::-webkit-scrollbar-track {
      background-color: transparent !important;
    }

    tbody {
      display: block;
      overflow-y: auto;
      height: 100%;
      border: 1px solid #eeeeee;
      border-radius: 0 0px 4px 4px;
      width: calc(100% - 2px);

      tr {
        border-bottom: 1px solid #eeeeee;
        display: grid;
        // grid-template-columns: 13% 24% 11% 11% 10% 21% 10%;
        grid-template-columns: 150px 1fr 110px 100px 170px 170px 110px 1fr 100px;
        width: 100%;
        align-items: center;

        &:hover {
          background-color: $gray5;
        }

        td {
          padding: 4px 10px;
          display: flex;
          word-break: break-word;
        }
      }
      &:last-child td {
        border: none;
      }

      td {
        color: $gray1;
      }
    }

    th,
    td {
      align-items: center;
    }
  }
}

.th_padding {
  display: flex;
  align-items: center;
  height: 12px;
}

.reset_button {
  margin-right: 5px;
}

.td_space_between {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}

// styling for action buttons section
.action_buttons {
  display: flex;
  justify-content: space-between;
}

.datePicker {
  width: 85px;
  max-height: 50px;
}

.date_range_filter_section {
  margin-left: 25px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin-right: 25px;
  flex-wrap: wrap;

  @media (max-width: 872px) {
    margin-left: 0;
  }
  @media (max-width: 561px) {
    justify-content: left;
    margin-right: 0;
  }
  @media (max-width: 536px) {
    column-gap: 20px;
    margin-right: 0;
    align-content: flex-start;
    button {
      margin: 0;
    }
    margin-top: 10px;
  }
  @media (max-width: 400px) {
    div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    input {
      width: calc(100% - 57px);
    }
  }
}

.gapping_element {
  width: 10px;
}

.leave_type_filter_section {
  border: 1px solid $button-border;
  border-radius: 6px;
  height: 32px;
  padding: 5px 10px;
}

.search_by_descriptoin_section {
  border: 1px solid $button-border;
  border-radius: 6px;
  height: 20px;
  padding: 5px 10px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  input {
    width: 140px;
    border: 0;
    outline: none;
  }
  @media (max-width: 847px) {
    margin-left: 0;
  }
  @media (max-width: 733px) {
    margin-left: 20px;
  }
  @media (max-width: 721px) {
    margin-left: 0;
  }
  @media (max-width: 713px) {
    margin-left: 20px;
  }
  @media (max-width: 446px) {
    margin-left: 0;
  }
  @media (max-width: 406px) {
    width: -webkit-fill-available;
  }
}

.filter_by_leave_type_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  @media (max-width: 401px) {
    width: -webkit-fill-available;
  }
}

.delete_icon {
  color: $badge_rejected;
}

@media (max-width: 1400px) {
  .mainArea {
    overflow: auto;
  }

  .table_wrapper {
    overflow-x: unset;
    padding-bottom: 36px;
    height: unset;

    thead {
      overflow: hidden;
      position: sticky;
      top: -18px;
      z-index: 10;
    }

    tbody {
      overflow: unset;
    }
  }

  .pagination {
    position: fixed;
    bottom: 0px;
    background-color: #ffffff;
    padding-bottom: 18px;
  }
}
