@import '/src/styles/colors.scss';

.dropzone_section {
  margin: 20px 0;

  .typography .heading {
    margin-bottom: 8px;
    display: block;
  }

  #preview {
    display: flex;
    flex-direction: column;
    align-content: left;
    img {
      width: fit-content;
      height: 100px;
    }
    div {
      text-align: left;
    }
  }
  #dropzone {
    div:nth-child(2) {
      text-align: left;
    }
  }
}

.preview_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: self-end;
  height: max-content;
}

.baseStyle {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 10px 20px;
  border-width: 3px;
  border-radius: 6px;
  border-color: $gray3;
  border-style: dashed;
  background-color: $white;
  color: $gray2;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.focusedStyle {
  border-color: $blue1;
}

.acceptStyle {
  border-color: $green1;
}

.rejectStyle {
  border-color: $red1;
}
