// Main styles
html,
body,
#root,
.App {
  height: 100%;
  width: 100%;
}

body {
  font-family: 'Inter';
  margin: 0;
}
