@import '/src/styles/colors.scss';

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid $gray3;
  border-top: 10px solid $green2;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}
.center {
  min-width: max-content;
}

.spinner-container {
  margin: 50% 30%;
}

.spinner_disabled {
  display: none;
}

.btn_spinner {
  width: 20px;
  height: 20px;
  border: 5px solid $gray3;
  border-top: 5px solid $green2;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  margin: -250% -80% !important;
}

.spinner-wrapper {
  width: inherit;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  color: #fff;

  .loader {
    width: 48px;
    height: 48px;
    border: 5px solid #fff;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
