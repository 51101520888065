@import '/src/styles/colors.scss';
.btn {
  width: fit-content;
  border: none;
  cursor: pointer;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;

  margin: 10px 0px;

  .icon {
    width: 18px;
    padding: 0px;
  }
}

.btn.primary {
  background: #e5b285;
  color: #050037;

  &:hover {
    background: #e5b285f2;
  }
  &:active {
    transform: scale(1.025);
  }
}

.btn.success {
  background: #10a37f;
  color: #ffffff;

  &:hover {
    background: #10a37ef2;
  }
  &:active {
    transform: scale(1.025);
  }
}

.btn.dark {
  background: $gray2;
  color: $gray4;
  &:hover {
    background: $gray2_hov;
  }
  &:active {
    transform: scale(1.025);
  }
}

.btn.secondary {
  border: 1px solid #d1d5db !important;
  background: #f9fafb;
  color: #050037;

  &:hover {
    background: #f9fafbf2;
  }
  &:active {
    transform: scale(1.025);
  }
}

// Button styling
.btn.xl {
  height: 50px;
  padding: 13px 25px;
  font-size: 16px;
}
.btn.lg {
  height: 42px;
  padding: 9px 17px;
  font-size: 16px;
}
.btn.base {
  height: 38px;
  padding: 9px 17px;
  font-size: 14px;
}
.btn.sm {
  height: 30px;
  padding: 7px 11px;
  font-size: 12px;
}

.btn.disabled {
  opacity: 0.4;
}
