@import '/src/styles/colors.scss';

.summary {
  width: 110px;
  height: 110px;
  position: relative;
  border: 2px solid;
  border-radius: 6px;
  text-align: center;
}

.summary_card_top_bar {
  height: 6px;
  border-radius: 4px 4px 0 0;
}

// for representing different leave types
.summary_total {
  border-color: $summary_color_5;
  circle {
    stroke: $summary_color_5;
  }
  background-color: $summary_color_5_background;
}

.summary_casual {
  border-color: $summary_color_1;
  circle {
    stroke: $summary_color_1;
  }
  background-color: $summary_color_1_background;
}

.summary_medical {
  border-color: $summary_color_3;
  circle {
    stroke: $summary_color_3;
  }
  background-color: $summary_color_3_background;
}

.summary_annual {
  border-color: $summary_color_2;
  circle {
    stroke: $summary_color_2;
  }
  background-color: $summary_color_2_background;
}

.summary_lieu {
  border-color: $summary_color_4;
  circle {
    stroke: $summary_color_4;
  }
  background-color: $summary_color_4_background;
}

.inner {
  width: 74px;
  height: 74px;
  left: 4px;
  top: 4px;
  position: relative;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
}

.inner_textual {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inner_textual_numeric {
  display: flex;
  flex-direction: row;
  align-items: self-end;
}

.outer {
  width: 82px;
  height: 82px;
  border-radius: 50%;
  margin: auto;
  margin-top: 4px;
  background-color: $empty_stroke;
}

circle {
  fill: none;
  stroke-width: 4.5px;
  stroke-dasharray: 250;
}

.svg {
  position: absolute;
  top: 0;
  left: 0;
  height: 110px;
  width: 110px;
}
