@import '../../../styles/colorPalette';

.input-wrapper {
  .input-label {
    color: $admin-primary;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;

    .input-required {
      color: #f64c4c;
      margin-left: 2px;
    }
  }

  .input-textarea-box {
    max-width: calc(100% - 24px) !important;
    min-width: calc(100% - 24px) !important;
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d4d4dd;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    padding: 8px 12px;
    font-size: 16px;
    outline: none;
    min-height: 22px;
    font-family: inherit;

    &:focus {
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 2px $white,
        0px 0px 0px 4px $focus;
    }

    &:disabled {
      background-color: #f5f5f5;
      box-shadow: none;
      color: #b0b0b0;
      cursor: not-allowed;
    }
  }

  .input-error {
    border: 1px solid #f64c4c;
  }

  .error {
    color: #f64c4c;
    font-size: 12px;
    margin-top: 5px;
  }
}
