@import '../../../styles/colors.scss';

.badge {
  width: fit-content;
  border: none;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  color: $gray4;
}

// size variants
.badge.sm {
  padding: 2px 10px;
  border-radius: 20px;
  height: 20px;
  font-size: 12px;
  line-height: 16px;
}
.badge.lg {
  padding: 2px 12px;
  border-radius: 12px;
  height: 24px;
}

// color variants
.badge.primary {
  background-color: $badge_pending;
}
.badge.secondary {
  background-color: $black3;
}
.badge.success {
  background-color: $badge_approved;
}
.badge.warning {
  background-color: $yellow1;
}
.badge.danger {
  background-color: $badge_rejected;
}
