@import '../../../styles/colorPalette';
@import '../../../styles/shadow';

.btn-pro-base {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: ease-in-out 0.2s;
  min-height: 40px;
  font-weight: 400;
  font-size: 14px;

  &:focus {
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 2px $white,
      0px 0px 0px 4px $focus;
    border-radius: 6px;
  }
}

.btn-primary {
  background-color: $admin-primary;
  color: $white;

  &:hover {
    color: $blue-100;
    background-color: #17173c;
  }

  &:focus {
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 2px $white,
      0px 0px 0px 4px $focus;
    border-radius: 6px;
  }
}

.btn-white {
  border: 1px solid #d4d4dd;
  background-color: #ffffff;
  color: #2b2b57;

  &:hover {
    background-color: #f5f5f5;
  }
}

.button-loading {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: 8px;

  .button-loading-inner {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    border: 2px solid #ffffff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }

  .button-loading-inner:nth-child(1) {
    animation-delay: -0.45s;
  }
  .button-loading-inner:nth-child(2) {
    animation-delay: -0.3s;
  }
  .button-loading-inner:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
