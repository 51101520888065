.manage-employe-title.leave-summary {
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .summary-actions {
    display: flex;
    gap: 1rem;
  }
}

.employee-leave-summary-wrapper {
  table {
    width: 100%;
    border-spacing: 0;

    thead {
      background-color: #fafafa;
      position: sticky;
      top: -12px;
      transition: ease in-out 0.3s;

      tr {
        th {
          padding: 6px 0;
          color: #010136;
          font-size: 14px;
          font-weight: 500;
          border: 1px solid #e5e5ee;
        }
      }

      tr:first-child {
        th:first-child {
          border-top-left-radius: 6px;
        }

        th:last-child {
          border-top-right-radius: 6px;
        }
      }
    }

    tbody {
      tr {
        transition: background-color 0.2s ease;

        td {
          padding: 12px 0;
          border: 1px solid #e5e5ee;
          text-align: center;
          font-size: 13px;
          color: #1f1f1f;
          font-weight: 400;
        }

        &:last-child {
          td:first-child {
            border-bottom-left-radius: 6px;
          }

          td:last-child {
            border-bottom-right-radius: 6px;
          }
        }

        &:hover {
          background-color: #f2f2f2;
          cursor: pointer;

          td {
            background-color: transparent !important;
            font-weight: 600;
          }
        }
      }
    }
  }
}

.popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 60%;
  max-width: 600px;
}

.popup-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.close-icon {
  cursor: pointer;
  font-size: 24px;
  color: #888;
}
