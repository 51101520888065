.datepicker {
  width: 342px;
  height: 40px;
  border: 1px solid #d1d5db;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 0px 5px;
}

.html_datepicker {
  border: 1px solid #d1d5db;
  border-radius: 6px;
  height: 20px;
  padding: 5px 10px;
}
.label {
  font-size: small;
}
