.section1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  min-height: 58px;

  @media (max-width: 360px) {
    flex-direction: column;
    width: 100%;
  }
  /* @media (min-width: 2000px) {
    max-width: 1400px;
    justify-self: center;
    gap: 800px;
  } */
}

.left {
  display: inline-flex;
  flex-direction: column;
  padding: 0 32px;
}

.right {
  display: inline-flex;
  flex-direction: column;
  padding: 0 32px;
  @media (max-width: 360px) {
    margin-top: 10px;
  }
}

.section1_container {
  display: grid;
}
