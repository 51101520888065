$white: #fff;
$white2: #f7f7f8;
$white2_hov: #f7f7f866;
$black1: #000;
$black2: #202123;
$black3: #1f2937;
$blue1: #2b579a;
$blue2: #1e40af;
$gray1: #6b7280;
$gray2: #343541;
$gray3: #ececf1;
$gray4: #fef2f2;
$gray2_hov: #3c3d48;
$black3: #1f2937;
$blue1: #2b579a;
$blue2: #1e40af;
$gray1: #6b7280;
$gray2: #6b7280;
$gray3: #ececf1;
$gray4: #fef2f2;
$gray5: #f9fafb;
$green1: #047857;
$green2: #10a37f;
$red1: #dc2626;
$red2: #991b1b;
$yellow1: #92400e;

// Buttons
$button-border: #d1d5db;
$button-focused: #3b82f6;

// Inputs
$input-focused: #3b82f6;
$input-secondary: #6b7280;
$input-error: #ef4444;

// Modal
$modal-footer: #f9fafb;

// scrollbar
$scrollbar: #9c9c9c;

// pastel colors
$pastel_blue: #dbeafe; // blue
// $pastel_blue: #bfdbfe; // blue
$pastel_red: #fee2e2; // red
$pastel_yellow: #fef3c7; // yellow
$pastel_green: #d1fae5; // green
$pastel_gray: #f3f4f6; // gray
$pastel_beige: #fbebda; // beige

// colors for summary widget - stroke and border
$summary_color_1: #dbeafe;
$summary_color_2: #d1fae5;
$summary_color_3: #fee2e2;
$summary_color_4: #fef3c7;
$summary_color_5: #a6afff;

$empty_stroke: #ececf1;

// colors for summary widget - background
$summary_color_1_background: #dbeafe44;
$summary_color_2_background: #d1fae544;
$summary_color_3_background: #fee2e244;
$summary_color_4_background: #fef3c744;
$summary_color_5_background: #a6afff44;

// badge colors
$badge_pending: #60a5fa; // Pending
$badge_rejected: #f87171; // Rejected
$badge_approved: #34d399; // Approved
