@import '../../../styles/colors.scss';

.input-text-box,
.input-select-box,
.input-textarea {
  border-radius: 6px;
  background-color: $white;
  border: 1px solid $button-border;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  height: 35px;
  padding: 5px 10px;
  font-size: 16px;
  outline: none;
  width: 100%;

  &:focus {
    border: 2px solid $input-focused;
  }
}

.input-textarea {
  height: auto !important;
}

.error-border {
  border: 2px solid $input-error !important;
}
.input-error-message,
.error-text {
  color: $input-error !important;
}

.input-label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 600;
}

.input-helper-label {
  margin: 0px;
  font-size: 14px;
  color: $input-secondary;
  margin-top: 5px;
}
