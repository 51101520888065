@import '/src/styles/colors.scss';

.aprrove-screen {
  display: flex;
  flex-direction: column;
  width: auto;
  background-color: $white2;
  padding: 12px;

  form {
    .form-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;
      margin-bottom: 10px;

      .input-label {
        margin-bottom: 0;
      }

      .custom-dropdown {
        margin-left: unset;
        width: 200px;
      }
    }

    #adminReason {
      width: calc(100% - 24px) !important;
    }
  }

  .leave-request-chip {
    display: flex;
    gap: 10px;
  }
}

.custom-dropdown {
  margin-left: 50%;
}

.form {
  display: flex;
  justify-content: center;
  width: 1000px;
  padding: 20px 0;
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 400px);
    width: 50%;
    border-radius: 6px;
    background-color: $white2;
    align-self: center;
    padding: 0 25px;
    width: 80%;
    gap: 25px;
  }
  select {
    width: 40%;
  }
  label {
    margin-bottom: 0;
  }
}
.leave-details {
  display: flex;
  flex-direction: column;
  width: 100%;

  * {
    padding-bottom: 10px;
  }
}
