@import '../../../styles/colors.scss';

.typography {
  padding: 0;
  // display: flex;
  align-items: center;
}
// headings
.heading {
  font-weight: 500;
}
.heading1 {
  @extend .heading;
  font-size: 2.25rem;
}
.heading2 {
  @extend .heading;
  font-size: 1.875rem;
}
.heading3 {
  @extend .heading;
  font-size: 1.5rem;
}
.heading4 {
  @extend .heading;
  font-size: 1.25rem;
}
.heading5 {
  @extend .heading;
  font-size: 1.125rem;
}

// base fonts
.base {
  font-size: 1rem;
}
.base_regular {
  @extend .base;
  font-weight: 400;
}
.base_medium {
  @extend .base;
  font-weight: 500;
}

// small fonts
.small {
  font-size: 0.875rem;
}
.small_regular {
  @extend .small;
  font-weight: 400;
}
.small_medium {
  @extend .small;
  font-weight: 500;
}
.small_link {
  @extend .small_medium;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: $gray2;
  }
}
.small_bold {
  @extend .small;
  font-weight: bold;
}

//extra small fonts
.xs {
  font-size: 0.75rem;
}
.xs_regular {
  @extend .xs;
  font-weight: 400;
}
.xs_medium {
  @extend .xs;
  font-weight: 500;
}

// extra extra small fonts
.xx_small {
  font-size: x-small;
}

.gray {
  color: $gray1;
}

// weighted fonts
.bold {
  font-weight: bold;
}

.logo_bold {
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
