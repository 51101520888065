@import '../../../styles/colors.scss';

.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $white;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  padding: 4px;
  border-radius: 2px;
  max-height: 93vh;
  overflow-y: auto;

  .header {
    padding: 12px 8px 12px 24px;
    box-shadow: inset 0px -1px 0px #e5e7eb;
    background-color: $white;
    font-weight: 600;

    .close-btn {
      position: absolute;
      right: 8px;
      top: 8px;
    }
  }

  .body {
    min-height: 300px;
    padding: 1.5rem 1.5rem;
  }

  .footer {
    display: flex;
    align-items: center;
    padding: 10px 16px;
    background: $modal-footer;
    height: auto;

    .modal-option-btn {
      margin: 0px 5px;
    }
  }
}

.modal-box.sm {
  width: 400px;
}
.modal-box.md {
  width: 600px;
}
.modal-box.lg {
  width: 800px;
}

@media (max-width: 992px) {
  .modal-box.lg,
  .modal-box.md,
  .modal-box.sm {
    width: 100%;
  }
}

// scrollbar
div::-webkit-scrollbar {
  width: 12px;
  background-color: transparent;
}
div::-webkit-scrollbar-thumb {
  height: 10px;
  border-radius: 8px;
  border: 4px solid transparent;
  background-clip: content-box;
  background-color: $scrollbar;
}
div::-webkit-scrollbar-track {
  background-color: transparent !important;
}
