@import '../../../styles/colors.scss';
.divider {
    background-color: $gray3;
    border: none;
    padding: 0;
    margin: 0;
}

// orientation variants
.divider.hr.sm {
    width: 100%;
    height: 0.05em;
}
.divider.hr.lg {
    width: 100%;
    height: 0.1em;
}

.divider.vr.lg {
    height: 2em;
    width: 0.1em;
}
.divider.vr.sm {
    height: 1em;
    width: 0.08em;
}