@import '../../../styles/colors.scss';

.headerSection {
  background-color: $black2;
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  div {
    display: inherit;
    flex-direction: row;
    height: fit-content;
    align-items: center;
  }

  @media (max-width: 365px) {
    height: 85px;
    flex-direction: column;
    justify-content: space-evenly;
  }
}

.child1 {
  padding-left: 32px;
}

.child2 {
  padding-right: 32px;
}
