@import '../../../styles/colorPalette';

.employee-info-view {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.employee-info-row {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #f0f0f0;
}

.employee-info-title {
  font-weight: 500;
  color: $admin-primary;
  width: 40%;
  max-width: 380px;
}

.employee-info-value {
  flex: 1;
  padding-left: 16px;
  font-weight: 400;
  color: $admin-primary;
}

.employee-info {
  width: 70%;

  .registration-step-wrapper {
    margin-top: 3rem;
  }
}

.view-employee {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
