@import '../../../styles/colorPalette';
@import '../../../styles/shadow';

.date-picker-container {
  .input-label {
    color: $admin-primary;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;

    .input-required {
      color: #f64c4c;
      margin-left: 2px;
    }
  }

  .input-error {
    border: 1px solid #f64c4c !important;
  }

  .error {
    color: #f64c4c;
    font-size: 12px;
    margin-top: 5px;
  }
}

.react-datepicker-wrapper {
  display: inherit;

  .react-datepicker__input-container {
    input {
      width: calc(100% - 24px);
      border-radius: 6px;
      background-color: #fff;
      border: 1px solid #d4d4dd;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
      padding: 8px 12px;
      font-size: 16px;
      outline: none;
      min-height: 22px;

      &:focus {
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 2px $white,
          0px 0px 0px 4px $focus;
      }

      &:disabled {
        background-color: #f5f5f5;
        box-shadow: none;
        color: #b0b0b0;
        cursor: not-allowed;
      }
    }
  }
}

.react-datepicker-popper {
  padding-top: 0 !important;

  @extend .shadow-xl;

  display: flex;
  box-sizing: border-box;
  background-color: $white !important;
  border: unset !important;
  border-radius: 4px;

  .react-datepicker__triangle {
    stroke: #aeaeae !important;
    fill: white !important;
  }

  .react-datepicker__month-container {
    float: unset;
  }

  .react-datepicker__header {
    background-color: $white !important;
    border-bottom: unset !important;
  }

  .datepicker-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px 12px 20px;

    .month-button-prev,
    .month-button-next {
      width: 24px;
      cursor: pointer;
    }

    .month-year {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h5 {
        margin: 0 2px;
        cursor: pointer;
      }
    }
  }

  .react-datepicker__day-names {
    display: flex;
    justify-content: space-between;
    margin: 0 20px;
  }

  .react-datepicker__day {
    &:hover {
      color: $midnight;
      background-color: $gray-200;
      z-index: 1;
    }

    &:focus {
      background-color: $blue-600;
      box-sizing: border-box;
      box-shadow: 0px 0px 0px 2px #f2f2f2, 0px 0px 0px 4px $blue-600;
      border-radius: 4px;
      z-index: 1;
    }
  }

  .react-datepicker__day--selected {
    background-color: $blue-600;
    font-weight: inherit;
    z-index: 1;

    &:hover {
      color: $white;
      background-color: $blue-600;
    }
  }

  .react-datepicker__day--keyboard-selected {
    @extend .react-datepicker__day--selected;
  }

  .react-datepicker__day--disabled {
    color: $gray-400;
    cursor: not-allowed;

    &:hover {
      color: $gray-400;
      background-color: $white;
    }
  }

  .react-datepicker__month {
    display: flex;
    flex-direction: column;
    margin: 0 20px 20px 20px;

    .react-datepicker__week {
      display: flex;
      justify-content: space-around;
      padding: 1px;

      .react-datepicker__day {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px !important;
        height: 36px !important;
        margin: 1px 0 !important;
        text-align: center;
      }

      .react-datepicker__day--in-range {
        color: $midnight;
        background-color: $blue-200;
        border-radius: unset;
      }

      .react-datepicker__day--range-start {
        color: $white;
        background-color: $blue-600 !important;
        box-sizing: border-box !important;
        box-shadow: 0px 0px 0px 2px #f2f2f2, 0px 0px 0px 4px $blue-600 !important;
        border-radius: 20px 4px 4px 20px !important;
        z-index: 1 !important;
      }

      .react-datepicker__day--range-end {
        color: $white;
        background-color: $blue-600 !important;
        box-sizing: border-box !important;
        box-shadow: 0px 0px 0px 2px #f2f2f2, 0px 0px 0px 4px $blue-600 !important;
        border-radius: 4px 20px 20px 4px !important;
        z-index: 1 !important;
      }

      .react-datepicker__day--in-selecting-range {
        @extend .react-datepicker__day--in-range;
      }

      .react-datepicker__day--selecting-range-start {
        @extend .react-datepicker__day--range-start;
      }

      .react-datepicker__day--selecting-range-end {
        @extend .react-datepicker__day--range-end;
      }

      .react-datepicker__day--outside-month {
        background-color: $white !important;
        border: unset !important;
        box-shadow: unset !important;
        cursor: unset !important;
        color: $gray-300;
      }
    }

    .react-datepicker__month-wrapper {
      font-weight: 500;
      display: flex;
      justify-content: space-around;
      margin: 5px 0;

      .react-datepicker__month-text {
        width: 84px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
      }

      .react-datepicker__month--selected,
      .react-datepicker__month-text--keyboard-selected {
        background-color: $blue-600;
        font-weight: inherit;
      }
    }
  }
  .react-datepicker__year {
    display: flex;
    flex-direction: column;
    margin: unset;
    margin: -15px 0 15px 0;

    .react-datepicker__year-wrapper {
      display: grid;
      grid-template-columns: auto auto auto;
      justify-content: space-evenly;
      max-width: unset;
      font-weight: 500;

      .react-datepicker__year-text {
        width: 84px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        margin: 15px 0 0 0;
      }

      .react-datepicker__year-text--selected {
        background-color: $blue-600;
        font-weight: inherit;
      }

      .react-datepicker__year-text--keyboard-selected {
        @extend .react-datepicker__year-text--selected;
      }
    }
  }
}
