@import '/src/styles/colors.scss';

.dashboard_tile {
  background-color: #f7f7f8;
  width: 276px;
  height: 46px;
  display: flex;
  align-items: center;
  padding: 32px;
  border-radius: 6px;
  cursor: pointer;
  gap: 20px;

  .tile-sub-text {
    color: $gray2;
    margin-top: 6px;
    display: block;
  }

  &:hover {
    background: $gray3;
  }
  &:active {
    transform: scale(1.025);
  }
}
