.mainArea {
  display: flex;
  flex-direction: column;
  padding: 18px 32px;
  height: calc(100% - 232px);

  /*  @media (min-width: 2000px) {
    max-width: 1400px;
    justify-self: center;
  } */
}

.dashboard-main-area {
  display: flex;
  gap: 30px;
  padding: 18px 32px;
  height: calc(100% - 232px);
}
