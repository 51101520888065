@import '../../../styles/colors.scss';

.crumbs {
  padding-top: 0.5em;
  display: flex;
  align-items: center;

  * {
    text-decoration: none;
    color: $gray1;
    font-size: 14px;
  }
}

.crumb-arrow {
  margin: 0 5px;
  font-size: 18px !important;
  margin-bottom: -1px;
}

.crumbs .crumb:last-child {
  a {
    color: $blue2;
  }
}

.crumbs .crumb:last-child:after {
  display: none;
}
