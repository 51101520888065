.spacing {
  width: 15rem;
  display: flex;
  justify-content: space-between;
}

.color {
  color: white;
}

.pointer {
  :hover {
    cursor: pointer;
  }
}

.logo-container {
  align-items: flex-end !important;
  gap: 8px;

  .logo {
    width: 110px;
  }
}
