@import '../../../styles/colors.scss';
.breadcrumb {
    display: inline-flex;
    justify-content: space-between;
    align-items: flex-end;
    div {
        display: flex;
        flex-direction: row;
        padding-top: 0.5em;
        padding-right: 0.5em;
        color: gray;
        &:nth-last-child(1) {
            color: $blue1;
        }
    }
}